<template>
  <div 
    v-if="visible"
    :class="{...cssClasses}"
  >
    <fr-input
      id="fr-program-qualifier-checkbox"
      ref="inputField"
      v-model="fieldValue"
      class=""
      name="fr-program-qualifier-checkbox"
      type="checkbox"
      squash-errors="true"
      @handle-errors="handleErrors"
    />
    <fr-label 
      for-id="fr-program-qualifier-checkbox"
      class=""
    >
      {{ qualifier.label }}
    </fr-label> 
  </div>

  <fr-field-error v-if="visible && hasErrors()">
    {{ errors[0] }}
  </fr-field-error>
</template>

<script>
import FrLabel from '@/components/fields/base/FrLabel.vue'
import FrInput from '@/components/fields/base/FrInput.vue'
import { mapGetters, mapActions } from 'vuex'
import helpers from '@/helpers/helpers'
import validation from "@/helpers/validation";

export default {
  name: 'FrProgramQualifierCheckbox',
  
  components: {
    FrInput,
    FrLabel
  },

  props: {
    /**
     * The form field that is being rendered.
     */
    field: {
      type: Object,
      required: true
    },

    /** 
     * The value that is passed from the parent component through `v-model`.
     */
    modelValue: {
      type: [Array, Object, String, Number, Boolean],
      default: undefined
    }
  },

  emits: ['update:modelValue'],

  data() {
    return {
      errors: [],
    };
  },

  computed: {
    /**
     * The getters mapped from Vuex.
     */
    ...mapGetters({
      programs: 'getPrograms',
      selectedProgramId: 'getSelectedProgramId',
      formFields: 'getFormFields',
      submitObject: 'getSubmitObject',
      programsFilter: 'getProgramsFilter',
    }),

    /** 
     * The CSS classes to be applied to the element.
     * 
     * This is defined as a computed property so we can dynamically set classes.
     * 
     * @returns {Array}
     */
    cssClasses() {
      return {
        'fr-program-qualifier-checkbox': true
      }
    },

    /**
     * The value that is passed from the parent component through `v-model`.
     * 
     * This is wrapped as a computed property so that it may be bound 
     * as a `v-model` to a child component. Setting this up as a proxy 
     * bypasses the `Avoid mutating a prop directly` error thrown by Vue.
     * Instead, we intercept this mutation and pass it along to the parent.
     * 
     * @param {String} val
     * 
     * @returns {String}
     */
    fieldValue: {
      get() { return this.modelValue },
      set(val) { this.$emit('update:modelValue', val) }
    },

    /**
     * The program object that is currently selected.
     * 
     * @returns {Object}
     */
    selectedProgram() {
      return this.programs.find(program => program.id === this.selectedProgramId)
    },

    /**
     * The qualifier object for the currently selected program.
     * 
     * @returns {Object|null}
     */
    qualifier() {
      // All of these checks need to be in place for sanity.
      // I'll come back and refactor this at some point to make it prettier.
      if (
        ! this.selectedProgram || // There is no selected program.
        ! Object.prototype.hasOwnProperty.call(this.selectedProgram, 'programQualifier') || // The selected program doesn't have the qualifier key.
        ! Object.prototype.hasOwnProperty.call(this.selectedProgram.programQualifier, 'data') || // The selected program doesn't have the data key.
        Object.keys(this.selectedProgram.programQualifier.data).length === 0 || // The selected program doesn't have a qualifier.
        this.selectedProgram.programQualifier.data.type !== 'checkbox' // The selected program isn't of the 'checkbox' type.
      ) {
        return null
      }

      return this.selectedProgram.programQualifier.data
    },

    /**
     * For dynamic display of fields
     * @returns {Boolean}
     */
    visible() {
      return !!this.qualifier
    },
    
    /**
     * For dynamic label settings
     * @returns {String}
     */
    label() {
      //if no attribute, return null for the default form config val to display
       if(!this.field.meta.display) {
        return null
      }

      if(this.field.meta.display.length === 0) {
        return null
      }
      //if length, if display name is not null or an empty string, else default
      if(this.field.meta.display.length > 0 && this.field.meta.display[0].displayName && this.field.meta.display[0].displayName !== '')  {
        //if conditional logic evaluates to true
        if(helpers.conditionalLogicEvaluation('display', this.formFields, this.field.meta, this.submitObject, this.programsFilter)) {
          return this.field.meta.display[0].displayName
        }
        return null      
      }

      return null

    }    
  },

  watch: {
    /**
     * Sets the validation to ignore if the field is not visible
     * @param {*} newVal 
     */
    visible(newVal) { 
      let steps = validation.setIgnoreValidation(this.field.id, !newVal)
      const { schema, labelLut } = validation.generateStepsSchema(steps)
      this.setSchemaObjectValue({key: "schema", value: schema})
      if(!newVal){
        this.fieldValue = this.field.meta.default ?? ''
      }
    }
  },

  created() {
    let steps = validation.setIgnoreValidation(this.field.id, !this.visible)
    const { schema, labelLut } = validation.generateStepsSchema(steps)
    this.setSchemaObjectValue({key: "schema", value: schema})
  },

  methods: {
    /** 
     * The actions mapped from Vuex.
     */
     ...mapActions(["setSchemaObjectValue"]),
     
    focus() {
      this.$refs.inputField.focus();
    },

    handleErrors(errors){
      this.errors = errors
    },

    hasErrors(){
      return this.errors.length > 0
    }
  }
}
</script>

<style lang="scss" scoped>
.fr-program-qualifier-checkbox { 
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  flex-wrap: wrap;
  label {
    font-size:10px;
    text-transform: none;
    font-weight: normal;
  }
}
</style>

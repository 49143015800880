<template>
  <span v-if="hasSlotContent" class="fr-field-error">
    <fr-field-error-icon />
    <slot />
  </span>
</template>

<script>
import FrFieldErrorIcon from './FrFieldErrorIcon.vue'

export default {
  name: 'FrFieldError',

  components: {
    FrFieldErrorIcon,
  },

  computed: {
    hasSlotContent() {
      // check if the VNode content exists and is not empty
      if(this.$slots.default().length > 0 && this.$slots.default()[0].children) {
        return this.$slots.default()[0].children.trim().length > 0;
      }
      return false;
    },
  },
}
</script>

<style lang="scss" scoped>
.fr-field-error {
  color: black;
  border: solid 1px #f1ced5;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  padding: 6px;
}
// styling for phone field - since from the tel library margins are set to 0 with !important
.fr-phone .fr-field-error {
  margin-top: 5px;
}
//totally not real id to boost the specificity
:is(#totally-fake-specificity-id, .fr-field-error) {
  color: #000 !important;
}
</style>

export default {
  /**
   * The Form Config object.
   * 
   * This is where all of the data about the form lives.
   * 
   * This should not be modified.
   * 
   * @param {Object}
   */
  formConfig: {},

  /**
   * The Submit object.
   * 
   * This is where all of the RFI submission data is stored.
   * 
   * @param {Object}
   */
  submit: {},

  /**
   * Identifies form submission occurring.
   * 
   * This boolean identifies that a form submission is in progress.
   * 
   * @param {Boolean}
   */
  isSubmitting: false,

  /**
   * The submission URL for the form.
   * 
   * @param {String}
   */
  submissionUrl: 'https://rfi.edu.help/v1/leads',

  /**
   * The URL used for validating email addresses
   * 
   * @param {String}
   */
  emailValidationUrl: 'https://rfi.edu.help/v1/validate?field=email',

  /**
   * The URL used for validating phone numbers
   * 
   * @param {String}
   */
  phoneValidationUrl: 'https://rfi.edu.help/v1/validate?field=phone',

  /**
   * The URL used for validating zip/postal codes
   * 
   * @param {String}
   */
  zipValidationUrl: 'https://rfi.edu.help/v1/validate?field=zip',

  /**
   * Computed metadata about the form config steps.
   * 
   * @param {Object}
   */
  steps: {
    /**
     * The current step the form is on.
     * 
     * @param {Number}
     */
    current: 1
  },

  /**
   * The partner's name.
   * 
   * @param {String}
   */
  partnerName: 'PLEASE SET THE PARTNER NAME',

  /**
   * The embed id.
   * 
   * @param {String}
   */
   embedId: '',

  /**
   * The data to use when filter programs.
   * 
   * @param {Object}
   */
  programsFilter: {},

  /**
   * The fields that should not be rendered.
   * 
   * @param {Array}
   */
  hiddenFields: [],

  /**
   * The page context for which form variant should be loaded.
   *
   * @param {String}
   */
  pageContext: '',

  /**
   * The form variant object.
   *
   * @param {Object}
   */
   formVariant: {},
   
   /**
    * The last phone entry that passed validation.
    * 
    * @param {String}
    */
   lastPhone: '+1 ',

   /**
    * The last email entry that passed validation.
    * 
    * @param {String}
    */
   lastEmail: '',

   /**
    * The last zipcode entry that passed validation.
    * 
    * @param {String}
    */
   lastZip: '',

   /**
   * The schemaObject used for validations
   * 
   * @param {Object}
   */
   schemaObject: {schema: {}, lut: {}, errors: {}},

  /**
   * The schemaObject used for validations
   * 
   * @param {Object}
   */
   schemaOverrides: { fields: {} },

  /**
   * The programs that are available on the form.
   * 
   * @param {Array}
   */
  availablePrograms: [],

  /**
   * The dial code.
   * 
   * @param {String}
   */
    dialCode: '',

  /**
   * The current thank you page.
   * 
   * @param {String}
   */
  thankYouPage: '',
  
  /**
   * The object to store all redirects (default + additional).
   * 
   * @param {Object}
   */
  additionalRedirects: {},  

}
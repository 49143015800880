<template>
  <div
    v-if="visible"
    :class="{...cssClasses}"
  >
    <template
      v-for="content, index of sharedContent"
      :key="shared-content-`${index}`"
    >
      <component
        :is="header"
        class="fr-content-header"
      >
        {{ content.content.title }}
      </component>
      <span
        class="fr-content-text"
        v-html="formatInfoText(content.content)"
      />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FrProgramContent',
  props: {
    /**
     * The form field that is being rendered.
     */
     field: {
      type: Object,
      required: true
    },
  },
  computed: {
    /**
    * The getters mapped from Vuex.
    */
    ...mapGetters({
    selectedProgram: 'getSelectedProgram',
    }),

    /** 
     * The CSS classes to be applied to the element.
     * 
     * This is defined as a computed property so we can dynamically set classes.
     * 
     * @returns {Object}
     */
     cssClasses() {
      return {
        'fr-program-content': true
      }
    },    
            
    /**
    * The shared content object for the currently selected program.
    * 
    * @returns {Object|null}
    */
    sharedContent() {
    // All of these checks need to be in place for sanity.
    // I'll come back and refactor this at some point to make it prettier.
    if (
        ! this.selectedProgram || // There is no selected program.
        ! Object.prototype.hasOwnProperty.call(this.selectedProgram, 'content') || // The selected program doesn't have the qualifier key.
        ! Object.prototype.hasOwnProperty.call(this.selectedProgram.content, 'data') || // The selected program doesn't have the data key.
        Object.keys(this.selectedProgram.content.data).length === 0) // The selected program doesn't have a qualifier.
    {
        return null
    }

    return this.selectedProgram.content.data
    },

    /**
    * For dynamic display of fields
    * @returns {Boolean}
    */
    visible() {
    return !!this.sharedContent
    },

    //return what header class - header-h4 is going to be a fallback
    header() {
      let cssClass = this.field.meta.classes.find((el) => el.startsWith('head')) ?? 'heading-h4'
      let regex = new RegExp(`(heading|header)-`, 'g')
      return cssClass.replace(regex, '')
    }
  },
  methods: {
    // Format the information text for the selected program - the custom [link-n] properties are replaced via regex.
    formatInfoText(info) {
      let infoText = info.text
      info.links.forEach((link, index) => {
        let linkRegex = new RegExp(`\\[LINK_${index + 1}\\]`, 'g')
        infoText = infoText.replace(linkRegex, `<a target="_blank" href="${link.url}">${info.links[index].text}</a>` )
      })
      return infoText
    }
  },
}

</script>
<style lang="scss" scoped>
</style>